import { computed, ref, onBeforeMount } from 'vue';
import { useStore } from 'vuex';

import useRecipeItem from '@/modules/cms/composables/recipes/useRecipeItem';

const useRecipeSteps = () => {
  const store = useStore();
  const currentOrder = ref('');
  const { selectedRecipe } = useRecipeItem();

  onBeforeMount(() => {
    const orders = store.getters['cmsRecipes/getRecipeSelectedSteps'];
    addStep.value = { ...addStep.value, orden: orders.length + 1 };
  });

  const isLoadingAdd = ref(false);
  const addStep = ref({
    orden: '',
    titulo: '',
    descripcion: '',
  });

  const addStepMsg = ref('Agregar');
  const addStepShowMsg = ref(false);
  const addStepMsgStatus = ref('form-');

  const addRecipeStep = async () => {
    isLoadingAdd.value = true;
    addStepShowMsg.value = false;

    const { status, msg, recipeID } = await store.dispatch(
      'cmsRecipes/addRecipePreparationStep',
      addStep.value
    );

    isLoadingAdd.value = false;
    addStepMsg.value = msg;
    addStepMsgStatus.value += status ? 'success' : 'error';
    addStepShowMsg.value = true;

    setTimeout(() => {
      addStepMsg.value = '';
      addStepMsgStatus.value = 'form-';
      addStepShowMsg.value = false;

      if (status && recipeID) selectedRecipe(recipeID);
    }, 2800);
  };

  const isLoadingUpdate = ref(false);
  const stepMsg = ref('');
  const isShowMsgStep = ref(false);
  const stepMsgStatus = ref('form-');

  const updateRecipeStep = async ({
    id: orden_id,
    title: titulo,
    order: orden,
    description: descripcion,
  }) => {
    isShowMsgStep.value = false;
    isLoadingUpdate.value = true;

    const { status, msg, recipeID } = await store.dispatch(
      'cmsRecipes/updateRecipePreparationStep',
      {
        orden_id,
        titulo,
        orden,
        descripcion,
      }
    );

    isLoadingUpdate.value = false;
    stepMsg.value = msg;
    stepMsgStatus.value += status ? 'success' : 'error';
    isShowMsgStep.value = true;

    setTimeout(() => {
      stepMsg.value = '';
      stepMsgStatus.value = 'form-';
      isShowMsgStep.value = false;

      if (recipeID) selectedRecipe(recipeID);
    }, 2800);
  };

  const isLoadingDelete = ref(false);

  const deleteRecipeStep = async (order_id) => {
    isShowMsgStep.value = false;
    isLoadingDelete.value = true;

    const { status, msg, recipeID } = await store.dispatch(
      'cmsRecipes/deleteRecipePreparationStep',
      order_id
    );

    isLoadingDelete.value = false;
    stepMsg.value = msg;
    stepMsgStatus.value += status ? 'success' : 'error';
    isShowMsgStep.value = true;

    setTimeout(() => {
      stepMsg.value = '';
      stepMsgStatus.value = 'form-';
      isShowMsgStep.value = false;

      selectedRecipe(recipeID);
    }, 2800);
  };

  return {
    currentOrder,
    recipeSteps: store.getters['cmsRecipes/getRecipeSelectedSteps'],
    recipeOrderSteps: store.getters['cmsRecipes/getRecipeSelectedStepOrders'],
    recipeFilterByOrder: (value) => (currentOrder.value = value),
    currentRecipeSteps: computed(() =>
      store.getters['cmsRecipes/getRecipeSelectedStepByOrder'](
        currentOrder.value
      )
    ),

    addRecipeStep,
    isLoadingAdd,
    addStepShowMsg,
    addStepMsg,
    addStepMsgStatus,

    updateRecipeStep,
    isLoadingUpdate,
    isShowMsgStep,
    stepMsg,
    stepMsgStatus,

    deleteRecipeStep,
    isLoadingDelete,
    addStep,
  };
};

export default useRecipeSteps;
