<template>
  <div class="mb-2">
    <Subtitle subtitle="Pasos de Preparación" :amount="recipeSteps.length" />

    <FormAddStep />
    <Separator v-if="recipeSteps.length > 0" />

    <Filter
      v-if="recipeSteps.length > 1"
      filterBy="orden"
      :currentDefault="currentOrder"
      :options="recipeOrderSteps"
      @update:filter="recipeFilterByOrder"
    />

    <template v-if="recipeSteps.length > 0">
      <template v-for="(step, index) in currentRecipeSteps" :key="index">
        <FormEditStep
          :id="step.id"
          :order="step.orden"
          :title="step.titulo"
          :description="step.descripcion"
        />
      </template>
    </template>

    <Empty v-else notFound="pasos de Preparación" />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import useRecipeSteps from '@/modules/cms/composables/recipes/useRecipeSteps';

export default {
  components: {
    Subtitle: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Subtitle" */ '@/modules/cms/components/Subtitle.vue'
      )
    ),
    Empty: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Empty" */ '@/modules/cms/components/Empty.vue'
      )
    ),
    Filter: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Filter" */ '@/modules/cms/components/Filter.vue'
      )
    ),
    Separator: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Separator" */ '@/modules/cms/components/Separator.vue'
      )
    ),
    FormAddStep: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "FormAddStep" */ '@/modules/cms/parts/recipes/steps/FormAddStep.vue'
      )
    ),
    FormEditStep: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "FormEditStep" */ '@/modules/cms/parts/recipes/steps/FormEditStep.vue'
      )
    ),
  },
  setup() {
    const {
      currentOrder,
      recipeSteps,
      recipeOrderSteps,
      recipeFilterByOrder,
      currentRecipeSteps,
    } = useRecipeSteps();

    return {
      recipeSteps,
      recipeOrderSteps,
      recipeFilterByOrder,
      currentOrder,
      currentRecipeSteps,
    };
  },
};
</script>
